<template>
  <power-bi-report :report-id="reportId" />
</template>

<script>
export default {
  components: {
    PowerBiReport: () => import('@/modules/external_bi/components/PowerBiReport')
  },
  computed: {
    reportId() {
      return 'd937b2bb-0e55-4e69-a6e2-c2bb96b96e96'
    }
  }
}
</script>
